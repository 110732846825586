<template>
  <div class="ele-body ele-body-card">
    <!-- 顶部统计卡片 -->
    <el-row :gutter="15" style="margin-bottom: 10px">
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">订单数量</div>
            <!--            <el-tag size="mini" type="success" style="margin-right: 10px" effect="plain">日</el-tag>-->
            <!--            <el-tag size="mini" type="" effect="plain">周</el-tag>-->
          </div>
          <div class="analysis-chart-card-num">{{ orderNumData.count }}</div>
          <div class="analysis-chart-card-content" style="padding-top: 18px;">
            <span class="ele-action">
              <span>周环比{{ orderNumData.weekProportion }}%</span>
              <i v-if="orderNumData.weekProportion > 0" class="el-icon-caret-top ele-text-success"></i>
              <i v-else class="el-icon-caret-bottom ele-text-danger"></i>
            </span>
            <span class="ele-action">
              <span>日环比{{ orderNumData.fayProportion }}%</span>
              <i v-if="orderNumData.fayProportion > 0" class="el-icon-caret-bottom ele-text-success"></i>
              <i v-else class="el-icon-caret-bottom ele-text-danger"></i>
            </span>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text">昨日订单 {{ orderNumData.dayCount }}</div>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">搜索量</div>
            <el-tag size="mini" class="date-select" style="margin-right: 10px" :effect="searchDayType===1?'light':'plain'" @click="tapSearchDayType(1)">昨日</el-tag>
            <el-tag size="mini" class="date-select" :effect="searchDayType===2?'light':'plain'" @click="tapSearchDayType(2)">本月</el-tag>
          </div>
          <div class="analysis-chart-card-num">{{ searchNumCountData.timeSlotCount }}</div>
          <div class="analysis-chart-card-content">
            <ele-chart
                ref="visitChart"
                style="height: 40px; width: 100%"
                :option="visitChartOption"/>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text">总搜索量 {{ searchNumCountData.count }}</div>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">交易总额</div>
            <el-tag size="mini" class="date-select">近30天</el-tag>
          </div>
          <div class="analysis-chart-card-num">¥ {{ searchOrderAmountData.timeSlotCount }}</div>
          <div class="analysis-chart-card-content">
            <ele-chart
                ref="payNumChart"
                style="height: 40px;"
                :option="payNumChartOption"/>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text">转化率 {{ searchOrderAmountData.conversionRate?searchOrderAmountData.conversionRate:'-' }}%</div>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">数据回写</div>
            <!--            <el-tag size="mini" type="success" style="margin-right: 10px" effect="plain">日</el-tag>-->
            <!--            <el-tag size="mini" type="" effect="plain">周</el-tag>-->
          </div>
          <div class="analysis-chart-card-num">{{ wriyBackCountData.count }}</div>
          <div class="analysis-chart-card-content" style="padding-top: 18px;">
            <span class="ele-action">
              <span>周环比{{ wriyBackCountData.weekProportion }}%</span>
              <i v-if="wriyBackCountData.weekProportion > 0" class="el-icon-caret-top ele-text-success"></i>
              <i v-else class="el-icon-caret-bottom ele-text-danger"></i>
            </span>
            <span class="ele-action">
              <span>日环比{{ wriyBackCountData.dayCount }}%</span>
              <i v-if="wriyBackCountData.dayCount > 0" class="el-icon-caret-top ele-text-success"></i>
              <i v-else class="el-icon-caret-bottom ele-text-danger"></i>
            </span>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text">上次回写时间 {{ wriyBackCountData.lastWritTime?getCurrentDate(wriyBackCountData.lastWritTime):'-' }}</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 销售额、访问量 -->
    <el-card shadow="never" body-style="padding: 0;" style="margin-bottom: 10px">
      <div class="ele-cell demo-monitor-tool">
        <div class="ele-cell-content" style="flex: 1">
          <el-tabs
              v-model="saleSearch.type"
              class="demo-monitor-tabs"
              @tab-click="onSaleTypeChange">
            <el-tab-pane label="总销售额" name="saleroom"/>
            <el-tab-pane label="会员" name="visits"/>
            <el-tab-pane label="流量包" name="flowPackage"/>
          </el-tabs>
        </div>
        <div style="display: flex; padding-top: 20px; box-sizing: border-box">
          <div class="ele-inline-block hidden-xs-only">
            <el-radio-group size="small" v-model="salesVolumeCountDayType" @change="dayTypeChange">
              <el-radio-button :label="1">昨天</el-radio-button>
              <el-radio-button :label="2">本周</el-radio-button>
              <el-radio-button :label="3">本月</el-radio-button>
              <el-radio-button :label="4">本年</el-radio-button>
            </el-radio-group>
          </div>
          <div class="ele-inline-block hidden-sm-and-down" style="width: 260px;">
            <el-date-picker
                unlink-panels
                type="daterange"
                class="ele-fluid"
                end-placeholder="结束日期"
                start-placeholder="开始日期"
                v-model="salesVolumeCountDayDate"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                range-separator="至" size="small"
                :picker-options="pickerOptions"
                @change="salesVolumeCountDayChange"
            />
          </div>
        </div>
      </div>
      <el-divider/>
      <el-row>
        <el-col :lg="18" :md="16">
          <div class="demo-monitor-title">
            销售金额（元）
          </div>
          <ele-chart
              ref="saleChart"
              style="height: 285px;"
              :option="saleChartOption"/>
        </el-col>
        <el-col :lg="6" :md="8">
          <div class="demo-monitor-title">
            套餐销售排名
          </div>
          <div
              v-for="(item, index) in packageSalesData"
              :key="index"
              class="demo-monitor-rank-item ele-cell" style="display: flex">
            <el-tag
                size="mini"
                type="info"
                :color="index < 3 ? '#314659' : 'hsla(0, 0%, 60%, .2)'"
                style="border-color: transparent; margin-right: 5px"
                class="ele-tag-round">
              {{ index + 1 }}
            </el-tag>
            <div class="ele-cell-content">{{ item.packageName }}</div>
            <div class="ele-text-secondary">{{ item.totalSales }}</div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 最近1小时访问情况 -->
    <el-row :gutter="15">
      <el-col :lg="24" :md="24">
        <el-card
            shadow="never"
            body-style="padding: 10px 5px 0 0;">
          <div slot="header" class="ele-cell" style="flex-direction: column">
            <div class="ele-cell-content">访问情况</div>
            <div style="display: flex; justify-content: space-between; align-content: center">
              <el-tabs
                  v-model="saleSearch1.type"
                  class="demo-monitor-tabs"
                  @tab-click="onVisitsCountChange">
                <el-tab-pane label="点播量" name="saleroom"/>
                <el-tab-pane label="点赞量" name="visits"/>
                <el-tab-pane label="收藏量" name="flowPackage"/>
              </el-tabs>
              <el-radio-group style="margin-top: 10px" v-model="visitsCountDayType" size="small" @input="accessGroupChange">
                <el-radio-button :label="2">本周</el-radio-button>
                <el-radio-button :label="3">本月</el-radio-button>
                <el-radio-button :label="4">本年</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <ele-chart
              ref="visitHourChart"
              style="height: 323px;"
              :option="visitHourChartOption"/>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EleChart from 'ele-admin/packages/ele-chart';
import {
  dp_dataWriyBackCount,
  dp_orderAmount,
  dp_orderNumCount, dp_packageSales,
  dp_salesVolumeCount,
  dp_searchNumCount, dp_visitsCount
} from "../../api/analysis";
import {getCurrentDate} from "@/utils/func";

export default {
  name: 'DashboardAnalysis',
  components: {EleChart},
  data() {
    return {
      pickerOptions: {
        disabledDate:(time)=> {
          console.log(time)
          if (!this.salesVolumeCountDayDate || this.salesVolumeCountDayDate.length !== 1) {
            // 如果未选择日期范围，则所有日期都可选
            return false;
          }
          const startDate = new Date(this.salesVolumeCountDayDate[1]);
          const endDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
          return time.getTime() > endDate.getTime();
        }
      },
      // 销售量搜索参数
      searchDayType: 2,
      saleSearch: {
        type: 'saleroom',
        dateType: 0,
        datetime: ''
      },
      saleSearch1: {
        type: 'saleroom',
        dateType: 0,
        datetime: ''
      },
      accessGroup: {
        type: 'saleroom',
        dateType: 0,
        datetime: ''
      },
      // 销售量数据
      saleroomData: [],
      // 销售量排名数据
      saleroomRankData: [],
      // 最近1小时访问情况数据
      visitHourData: [],
      orderNumData: {},
      searchNumCountData: {},
      //
      searchNumCountxAxisData: [],
      searchNumCountSeriesData: [],
      //
      searchOrderAmountData: {},
      searchOrderAmountxAxis: [],
      searchOrderAmountSeries: [],
      //
      wriyBackCountData: {},
      //
      salesVolumeCountDayType: 3,
      salesVolumeCountDayDate: [],
      salesVolumeCountDayData: [],
      salesVolumeCountxAxis: [],
      salesVolumeCountxSeries: [],
      //
      packageSalesData: [],
      //
      visitsCountDayType: 3,
      collectCountdata: [],
      playNumCountData: [],
      zanCountData: [],
      visitsCountDayDate: [],
      visitsCountIndex:'0',
    };
  },
  computed: {
    // 访问量折线图配置
    visitChartOption() {
      return {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['搜索量'],
          right: 0,
          show: false,
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        },
        xAxis: [
          {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: this.searchNumCountxAxisData
          }
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '搜索量',
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.5
            },
            data: this.searchNumCountSeriesData
          }
        ]
      };
    },
    // 支付笔数柱状图配置
    payNumChartOption() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: '<i class="ele-chart-dot" style="background: #3aa1ff;"></i>{b0} <br/> 总金额:{c0}'
        },
        grid: {
          top: 0,
          bottom: 1,
          left: 0,
          right: 0
        },
        xAxis: [
          {
            type: 'category',
            data: this.searchOrderAmountxAxis
          }
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'bar',
            smooth: true,
            symbol: 'none',
            showBackground: true,
            areaStyle: {
              opacity: 0.5
            },
            data: this.searchOrderAmountSeries
          }
        ]
      }
    },
    // 销售额柱状图配置
    saleChartOption() {
      return {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: [
          {
            type: 'category',
            data: this.salesVolumeCountxAxis
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            data: this.salesVolumeCountxSeries
          }
        ]
      };
    },
    // 访问情况折线图配置
    visitHourChartOption() {
      let tooltip = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: [''],
          show: false,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.visitsCountDayDate
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: []
      }
      console.log(this.visitsCountIndex)
      switch (Number(this.visitsCountIndex)) {
        case 0:
          tooltip.legend.legend = ['点播量']
          tooltip.series = [
            {
              name: '点播量',
              type: 'line',
              smooth: true,
              symbol: 'none',
              areaStyle: {
                opacity: 0.5
              },
              data: this.playNumCountData
            }
          ]
          return tooltip
        case 1:
          tooltip.legend.legend = ['点赞量']
          tooltip.series = [
            {
              name: '点赞量',
              type: 'line',
              smooth: true,
              symbol: 'none',
              areaStyle: {
                opacity: 0.5
              },
              data: this.zanCountData
            }
          ]
          return tooltip
        case 2:
          tooltip.legend.legend = ['收藏量']
          tooltip.series = [
            {
              name: '收藏量',
              type: 'line',
              smooth: true,
              symbol: 'none',
              areaStyle: {
                opacity: 0.5
              },
              data: this.collectCountdata
            }
          ]
          return tooltip
        default:
          return tooltip
      }
    }
  },
  watch: {
    salesVolumeCountDayDate(val) {
      // 检查选定范围是否超过30天
      if (val && val.length === 2) {
        const startDate = new Date(val[0]);
        const endDate = new Date(val[1]);
        const days = (endDate - startDate) / (24 * 3600 * 1000);
        if (days > 30) {
          this.$message.error('选择日期范围不能超过30天！');
          this.salesVolumeCountDayDate = []; // 清空日期范围
        }
      }
    }
  },
  mounted() {
    this.getSaleroomData();
    //
    this.orderNum()
    this.searchNumCount()
    this.orderAmount()
    this.dataWriyBackCount()
    this.salesVolumeCount()
    this.packageSales()
    this.visitsCount()
  },
  methods: {
    getCurrentDate,
    onVisitsCountChange(e){
      this.visitsCountIndex = e.index
    },
    tapSearchDayType(dayType) {
      this.searchDayType = dayType
      this.searchNumCount()
    },
    async orderNum() {
      try {
        const r = await dp_orderNumCount()
        this.orderNumData = r.data
      } catch (e) {
        console.log(e)
      }
    },
    async searchNumCount() {
      try {
        const r = await dp_searchNumCount({dayType: this.searchDayType})
        this.searchNumCountData = r.data
        this.searchNumCountxAxisData = r.data.timeSlot.map(item => item.date)
        this.searchNumCountSeriesData = r.data.timeSlot.map(item => item.count)
      } catch (e) {
        console.log(e)
      }
    },
    async orderAmount() {
      try {
        const r = await dp_orderAmount()
        this.searchOrderAmountData = r.data
        this.searchOrderAmountxAxis = r.data.timeSlot.map(item => item.date)
        this.searchOrderAmountSeries = r.data.timeSlot.map(item => item.successAmountCount)
      } catch (e) {
        console.log(e)
      }
    },
    async dataWriyBackCount() {
      try {
        const r = await dp_dataWriyBackCount()
        this.wriyBackCountData = r.data
      } catch (e) {
        console.log(e)
      }
    },
    async salesVolumeCount() {
      try {
        let queryData = {}
        if (this.salesVolumeCountDayType) queryData.dayType = this.salesVolumeCountDayType
        if (this.salesVolumeCountDayDate && this.salesVolumeCountDayDate.length) {
          queryData.startDate = this.salesVolumeCountDayDate[0]
          queryData.endDate = this.salesVolumeCountDayDate[1]
          delete queryData.dayType
        }
        if(!this.salesVolumeCountDayType && !this.salesVolumeCountDayDate.length) return
        const r = await dp_salesVolumeCount(queryData)
        this.salesVolumeCountDayData = r.data
        this.getSaleroomData()
      } catch (e) {
        console.log(e)
      }
    },
    dayTypeChange() {
      this.salesVolumeCountDayDate = []
      this.salesVolumeCount()
      this.packageSales()
    },
    // 套餐销售排名
    async packageSales() {
      try {
        let queryData = {}
        if (this.salesVolumeCountDayType) queryData.dayType = this.salesVolumeCountDayType
        if (this.salesVolumeCountDayDate && this.salesVolumeCountDayDate.length) {
          queryData.startDate = this.salesVolumeCountDayDate[0]
          queryData.endDate = this.salesVolumeCountDayDate[1]
          delete queryData.dayType
        }
        const r = await dp_packageSales(queryData)
        if(this.saleSearch.type === 'saleroom'){
          const rData =  r.data.slice(0,8)
          this.packageSalesData = rData
        }
        if(this.saleSearch.type === 'visits'){
          const vipData = r.data.filter(item=>item.type === 1)
          const vipDataSort = vipData.sort((a,b)=> b.totalSales - a.totalSales)
          const vipDataSlice = vipDataSort.slice(0,8)
          this.packageSalesData = vipDataSlice
        }
        if(this.saleSearch.type === 'flowPackage'){
          const vipData = r.data.filter(item=>item.type === 2)
          const vipDataSort = vipData.sort((a,b)=> b.totalSales - a.totalSales)
          const vipDataSlice = vipDataSort.slice(0,8)
          this.packageSalesData = vipDataSlice
        }
      } catch (e) {
        console.log(e)
      }
    },
    /* 总销量，会员，流量切换 */
    getSaleroomData() {
      this.salesVolumeCountxAxis = this.salesVolumeCountDayData.map(item => item.date)
      if (this.saleSearch.type === 'saleroom') {
        this.salesVolumeCountxSeries = this.salesVolumeCountDayData.map(item => item.vipAmountCount + item.flowAmountCount)
      }
      if (this.saleSearch.type === 'visits') {
        this.salesVolumeCountxSeries = this.salesVolumeCountDayData.map(item => item.vipAmountCount)
      }
      if (this.saleSearch.type === 'flowPackage') {
        this.salesVolumeCountxSeries = this.salesVolumeCountDayData.map(item => item.flowAmountCount)
      }
    },
    salesVolumeCountDayChange() {
      this.salesVolumeCountDayType = null
      this.salesVolumeCount()
      this.packageSales()
    },
    async visitsCount() {
      try {
        const r = await dp_visitsCount({
          dayType: this.visitsCountDayType
        })
        this.visitsCountDayDate = r.data.map(item => item.date)
        this.collectCountdata = r.data.map(item => item.collectCount)
        this.playNumCountData = r.data.map(item => item.playNumCount)
        this.zanCountData = r.data.map(item => item.zanCount)
      } catch (e) {
        console.log(e)
      }
    },
    /* 销售量tab选择改变事件 */
    onSaleTypeChange() {
      this.getSaleroomData()
      this.packageSales()
    },
    accessGroupChange() {
      this.visitsCount()
    }
  },
  activated() {
    ['visitChart', 'payNumChart', 'saleChart', 'visitHourChart', 'hotSearchChart'].forEach((name) => {
      this.$refs[name].resize();
    });
  }
}
</script>

<style scoped>
/* 统计卡片 */
.analysis-chart-card-num {
  font-size: 30px;
}

.analysis-chart-card-content {
  height: 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  width: 100%;
}

/* 销售额、访问量工具栏 */
.demo-monitor-tool {
  padding: 0 20px;
}

.demo-monitor-tool ::v-deep .el-tabs__nav-wrap:after {
  display: none;
}

.demo-monitor-tool ::v-deep .el-tabs__item {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
}

.demo-monitor-tool .el-date-editor {
  width: 256px;
  margin-left: 10px;
}

/* 小标题 */
.demo-monitor-title {
  padding: 0 20px;
  margin: 15px 0 5px 0;
}

/* 排名item */
.demo-monitor-rank-item {
  padding: 0 20px;
  line-height: 20px;
  margin-top: 18px;
}

.demo-monitor-tool {
  display: flex;
  align-items: center;
}

.ele-cell {
  display: flex;
}

.ele-cell .ele-cell-content {
  flex: 1;
}

.ele-text-danger {
  color: #F56C6C;
}

.ele-text-success {
  color: #67C23A;

}

.date-select {
  cursor: pointer;
}
</style>
<style>
.ele-body-card .el-tabs__header {
  margin-bottom: -10px;
}

.ele-body-card .ele-charts {
  overflow: inherit !important;
}

.ele-body-card .el-tabs__nav-wrap::after{
  position: inherit;
}
</style>
